const initialState = {
     stateSelected: null,
     states: null,
     statesFile: null,
     comparisonChoosedItems: ['4d2fd55b-55e5-5ce2-bc4f-6da867db5318'],
     comparisonShowExclusionsAdditionalLimitations: false,
     comparisonShowAlternativeholisticTherapy: false,
     comparisonShowAnnualExamRequirement: false,
     previewIncludes: null,
     isPreview: false,
     previewPage: null,
     trackingCode: null,
     trackingPhone: null,
     infoArticle: null,
     employerGroupsData: null
};

export const getTrackingCode = state => state.app.trackingCode || null;
export const getTrackingPhone = state => state.app.trackingPhone || null;
export const getInfoArticle = state => state.app.infoArticle || null;
export const getPreviewPage = state => state.app.previewPage || null;
export const getIsPreviewStatus = state => state.app.isPreview || false;
export const getComparisonChoosedItems = state => state.app.comparisonChoosedItems || false;
export const getComparisonShowExclusionsAdditionalLimitations = state => state.app.comparisonShowExclusionsAdditionalLimitations || false;
export const getComparisonShowAlternativeholisticTherapy = state => state.app.comparisonShowAlternativeholisticTherapy || false;
export const getComparisonShowAnnualExamRequirement = state => state.app.comparisonShowAnnualExamRequirement || false;
export const getEmployerGroupsData = state => state.app.employerGroupsData

export default (state = initialState, action) => {
     switch (action.type) {
          case 'TRACKING_CODE':
               return {
                    ...state,
                    trackingCode: action.payload,
               };
          case 'TRACKING_PHONE':
               return {
                    ...state,
                    trackingPhone: action.payload,
               };
          case 'GET_INFO_ARTICLE':
               return {
                    ...state,
                    infoArticle: action.payload,
               };
          case 'PREVIEW_STATE_INCLUDES':
               return {
                    ...state,
                    isPreview: true,
                    previewIncludes: action.payload,
               };
          case 'PREVIEW_STATE_PAGE':
               return {
                    ...state,
                    isPreview: true,
                    previewPage: action.payload,
               };
          case 'STATE_LOADED':
               return {
                    ...state,
                    states: action.payload,
               };
          case 'STATEFILE_LOADED':
               return {
                    ...state,
                    statesFile: action.payload,
               };
          case 'SET_STATECHANGE':
               return {
                    ...state,
                    stateSelected: action.payload,
               };
          case 'SET_COMPARISONCHECK':
               switch (action.payload.key) {
                    case 'comparisonShowExclusionsAdditionalLimitations':
                         return {
                              ...state,
                              comparisonShowExclusionsAdditionalLimitations: action.payload.value
                         }
                    case 'comparisonShowAlternativeholisticTherapy':
                         return {
                              ...state,
                              comparisonShowAlternativeholisticTherapy: action.payload.value
                         }
                    case 'comparisonShowAnnualExamRequirement':
                         return {
                              ...state,
                              comparisonShowAnnualExamRequirement: action.payload.value
                         }
               }
          case 'SET_COMPARISONCHECKALL':
               return {
                    ...state,
                    comparisonShowExclusionsAdditionalLimitations: true,
                    comparisonShowAlternativeholisticTherapy: true,
                    comparisonShowAnnualExamRequirement: true
               }
          case 'SET_COMPARISONUNCHECKALL':
               return {
                    ...state,
                    comparisonShowExclusionsAdditionalLimitations: false,
                    comparisonShowAlternativeholisticTherapy: false,
                    comparisonShowAnnualExamRequirement: false
               }
          case 'SET_COMPARISONPROVIDERS':
               let current = [...state.comparisonChoosedItems];
               let id = action.payload;
               if (current.includes(id)) {
                    const index = current.indexOf(id);
                    if (index > -1) {
                         current.splice(index, 1);
                    }
               } else {
                    if (current.length < 2) {
                         current.push(id);
                    } else {
                         current.pop();
                         current.push(id);
                    }
               }
               return {
                    ...state,
                    comparisonChoosedItems: current
               }
          case 'SET_EMPLOYER_GROUPS_DATA':
               return {
                    ...state,
                    employerGroupsData: action.payload
               }
          default:
               return state;
     }
}